// Make sure to sync with sitemap.xml

export namespace GuidesRoutes {
	export const root = '/guides';
	export const designGuide = '/guides/design-guide';
	export const twitterBanner = '/guides/twitter-banner';
	export const uiPatterns = '/guides/ui-design-patterns';
}

export namespace Routes {
	export const root = '/';
	export const gallery = '/gallery';
	export const generator = '/generator';
	export const scheduler = '/scheduler';
	export const unsubscribe = '/unsubscribe';
	export const confirmEmail = '/confirm-email';
	export const leaderboard = '/leaderboard';
	export const profile = '/profile';
	export const guides = GuidesRoutes.root;

	export const privacy =
		'https://coordinated-tomato-b1d.notion.site/Privacy-Policy-f907cf1099be4e7cb6ece6be5d73ada6';
	export const terms =
		'https://coordinated-tomato-b1d.notion.site/Terms-of-Service-0710beefd989423ab7b8bc6da270d4c9';
}

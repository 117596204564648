import { addDays, format } from 'date-fns';
import type { Database } from '../database.types';
import { timezones } from './timezones';
import type { ScheduleOptionType } from './types';

export const gtag = 'G-JKB4YMCLPT';

// UI configs
export const lsAppPrefix = 'uichallenges';

export const cookieDifficulty = 'difficulty-selector';
export const cookieChallengeGenerator = 'generated-challenge-prompt';
export const cookieHasConverted = 'has-converted';
export const cookieEmptyListKey = 'empty-list';

export const productHuntUrl = 'https://www.producthunt.com/posts/ui-challenges';

// User uuid for tracking
export const cookieUser = 'user-uuid';
export const cookieUserEmail = 'user-email';

export const emailOrigin = 'UI Challenges <challenges@uichallenges.design>';
export const adminEmailOrigin = 'Admin UIC <admin@uichallenges.design>';
export const adminEmail = 'uichallenges.design@gmail.com';

export const urlOrigin = 'https://www.uichallenges.design';

export const cdnOrigin = 'https://cdn.uichallenges.design';

export const bucketAssetsFolder = 'public-checkins/v1/';

export const emailUrlParamForEmail = 'e';

export const baseUrl = process.env.VERCEL_URL
	? // ? `https://${process.env.VERCEL_URL}`
	  'https://www.uichallenges.design' // because VERCEL_URL is vercel branch
	: 'http://localhost:3000';

export const getResendDefaultConfig = (plan?: string | null) => ({
	from: emailOrigin,

	// TODO do i need this?
	// ...(plan
	// 	? {
	// 			headers: {
	// 				'List-Unsubscribe': `${baseUrl}/${Routes.unsubscribe}?plan=${plan}`,
	// 			},
	// 	  }
	// 	: {}),
});

export const getSchedulePlanOptions = (
	currentDate: Date,
): ScheduleOptionType[] => [
	{
		key: '7',
		title: 'Week In Design',
		value: 7,
		description: `Starting today, we will send you an email with a unique challenge every day for 7 days, concluding on the ${format(
			addDays(currentDate, 7 - 1),
			'do',
		)} of ${format(addDays(currentDate, 7 - 1), 'MMMM')}.`,
	},
	{
		key: '30',
		title: 'Month Of UI',
		value: 30,
		description: `Starting today, we will send you an email with a unique challenge every day for 30 days, concluding on the ${format(
			addDays(currentDate, 30 - 1),
			'do',
		)} of ${format(addDays(currentDate, 30 - 1), 'MMMM')}.`,
	},
	{
		key: '100',
		title: '100 Strong',
		value: 100,
		description: `Starting today, we will send you an email with a unique challenge every day for 100 days, concluding on the ${format(
			addDays(currentDate, 100 - 1),
			'do',
		)} of ${format(addDays(currentDate, 100 - 1), 'MMMM')}, ${format(
			addDays(currentDate, 100),
			'yyyy',
		)}.`,
	},
	{
		key: '365',
		title: 'Year Of Growth',
		value: 365,
		description: `Starting today, we will send you an email with a unique challenge every day for 365 days, concluding on the ${format(
			addDays(currentDate, 365 - 1),
			'do',
		)} of ${format(addDays(currentDate, 365 - 1), 'MMMM')}, ${format(
			addDays(currentDate, 365),
			'yyyy',
		)}.`,
	},
];

export const getSchedulerState = (
	challenges?: Array<Database['public']['Tables']['challenges']['Row'] | null>,
) => {
	if (challenges?.some((challenge) => challenge?.active)) {
		return 'subscribed';
	}

	if (
		challenges?.some(
			(challenge) => challenge?.intro_email_sent && !challenge.active,
		)
	) {
		return 'email-sent';
	}

	return 'pristine';
};

export const lsKeys = {
	interestLeaderboard: 'showInterestLeaderboard',
	scheduleViewState: 'schedule-view-state',
	interestFeatures: 'interestFeatures',
	referralCode: 'referral-code',
};

export const hourSelectValues = [
	{ key: '1', value: '1 AM' },
	{ key: '2', value: '2 AM' },
	{ key: '3', value: '3 AM' },
	{ key: '4', value: '4 AM' },
	{ key: '5', value: '5 AM' },
	{ key: '6', value: '6 AM' },
	{ key: '7', value: '7 AM' },
	{ key: '8', value: '8 AM' },
	{ key: '9', value: '9 AM' },
	{ key: '10', value: '10 AM' },
	{ key: '11', value: '11 AM' },
	{ key: '12', value: '12 PM' },
	{ key: '13', value: '1 PM' },
	{ key: '14', value: '2 PM' },
	{ key: '15', value: '3 PM' },
	{ key: '16', value: '4 PM' },
	{ key: '17', value: '5 PM' },
	{ key: '18', value: '6 PM' },
	{ key: '19', value: '7 PM' },
	{ key: '20', value: '8 PM' },
	{ key: '21', value: '9 PM' },
	{ key: '22', value: '10 PM' },
	{ key: '23', value: '11 PM' },
	{ key: '24', value: '12 AM' },
];

export const defaultHourKey = '9';
export const defaultTimezone = 'America/Los_Angeles';

export const timezoneSupportedValue =
	Intl?.supportedValuesOf?.('timeZone') || timezones;
